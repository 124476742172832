import { authSlice } from '@ucheba/store/auth'
import { authPhoneSlice } from '@ucheba/store/auth/phone'
import { authPhoneCodeSlice } from '@ucheba/store/auth/phone/code'
import { advSlice } from '@ucheba/store/adv'
import { articlesSlice } from '@ucheba/store/articles'
import { chatBotSlice } from '@ucheba/store/chat-bot'
import { clientRequestSlice } from '@ucheba/store/client-request'
import { clientProductsRequestsSlice } from '@ucheba/store/client-products/requests'
import { clientProductsRequestsPaymentSlice } from '@ucheba/store/client-products/requests/payment'
import { coreSlice } from '@ucheba/store/core'
import { createReduxWrapper } from '@ucheba/utils/helpers/store'
import { dialogSlice } from '@ucheba/ui/components/Dialog/store'
import { egeSchoolSlice } from '@ucheba/store/ege/school'
import { egeSubjectsDetailSlice } from '@ucheba/store/ege/subjects/detail'
import { egeSubjectsScheduleSlice } from '@ucheba/store/ege/subjects/schedule'
import { egeSubjectsSlice } from '@ucheba/store/ege/subjects'
import { subjectsDetailSlice } from '@ucheba/store/subjects/detail'
import { institutionsRankingsSlice } from '@ucheba/store/institutions/rankings'
import { institutionsRankingsParticipantsSlice } from '@ucheba/store/institutions/rankings/detail/participants'
import { pageDataProfSlice } from '@ucheba/store/page-data/for-abiturients/prof'
import { eventsIdSlice } from '@ucheba/store/events/id'
import { eventsSlice } from '@ucheba/store/events'
import { eventsRubricsSlice } from '@ucheba/store/events/rubrics'
import { eventsOpenLessonsSlice } from '@ucheba/store/events/open-lessons'
import { eventsSearchSlice } from '@ucheba/store/events/search'
import { eventsMySlice } from '@ucheba/store/events/my'
import { eventsSimilarSlice } from '@ucheba/store/events/similar'
import { examsSlice } from '@ucheba/store/exams'
import { geoipSlice } from '@ucheba/store/geoip'
import { institutionsSlice } from '@ucheba/store/institutions'
import { institutionsEventsSlice } from '@ucheba/store/institutions/events'
import { institutionItemSlice } from '@ucheba/store/institutions/item'
import { divisionsSlice } from '@ucheba/store/institutions/divisions'
import { learningRequestSlice } from '@ucheba/store/institutions/learningRequest'
import { newsSlice } from '@ucheba/store/institutions/news'
import { feedbackSlice } from '@ucheba/store/institutions/feedbacks'
import { olympiadsSlice as institutionOlympiadsSlice } from '@ucheba/store/institutions/olympiads'
import { teachersSlice } from '@ucheba/store/institutions/teachers'
import { partnerEmployersSlice } from '@ucheba/store/institutions/partner-employers'
import { newsByIdSlice } from '@ucheba/store/institutions/newsById'
import { chancesOfAdmissionSlice } from '@ucheba/store/institutions/chancesOfAdmission'
import { institutionsRankingsStatsSlice } from '@ucheba/store/institutions/rankings/stats'
import { locationSlice } from '@ucheba/store/location'
import { notificationsSlice } from '@ucheba/ui/components/Notice/store'
import { olympiadsSlice } from '@ucheba/store/olympiads'
import { pageDataConsultSlice } from '@ucheba/store/page-data/for-abiturients/consult'
import { participantsSlice } from '@ucheba/store/events/participants'
import { personalAchievementsSlice } from '@ucheba/store/personalAchievements'
import { plannerSpecialtiesSlice } from '@ucheba/store/planner/specialties'
import { plannerProgramsSlice } from '@ucheba/store/planner/programs'
import { plannerStatisticsSlice } from '@ucheba/store/planner/statistics'
import { plannerPlanSlice } from '@ucheba/store/planner/plan'
import { profileFavoritesSlice } from '@ucheba/store/profile/favorites'
import { professionsSlice } from '@ucheba/store/professions'
import { profileSlice } from '@ucheba/store/profile'
import { profileShortSlice } from '@ucheba/store/profile/short'
import { specialitiesIdSimilarSlice } from '@ucheba/store/specialities/id/similar'
import { egeStatsSlice } from '@ucheba/store/ege/stats'
import { specialitiesSearchSlice } from '@ucheba/store/specialities/search'
import { stepFormsStartSlice } from '@ucheba/store/step-forms/start'
import { stepFormsStepSlice } from '@ucheba/store/step-forms/step'
import { stepFormsStepCompleteSlice } from '@ucheba/store/step-forms/step/complete'
import { stepFormsSlice } from '@ucheba/store/step-forms'
import { subjectsSlice } from '@ucheba/store/subjects'
import { specialitiesIdSlice } from '@ucheba/store/specialities/id'
import { tagsStatsSlice } from '@ucheba/store/tags/stats'
import { tagsTreeSlice } from '@ucheba/store/tags/tree'
import { userDataSlice } from '@ucheba/store/userData'
import { userTagsSlice } from '@ucheba/store/userTags'
import { eventSlice } from '@ucheba/store/event'
import { ogeSubjectsSlice } from '@ucheba/store/oge/subjects'
import { ogeSubjectsDetailSlice } from '@ucheba/store/oge/subjects/detail'
import { ogeSubjectsScheduleSlice } from '@ucheba/store/oge/subjects/schedule'
import { institutionsProgramTypesSlice } from '@ucheba/store/institutions/program-types'
import { institutionsProgramsSlice } from '@ucheba/store/institutions/programs'
import { pageDateHeaderOffersSlice } from '@ucheba/store/page-data/for-abiturients/headerOffers'
import { institutionsProgramIdSlice } from '@ucheba/store/institutions/programs/programId'
import { feedbacksSlice } from '@ucheba/store/feedbacks'
import { questionsSlice } from '@ucheba/store/questions'
import { profMotivationTestResultsSlice } from '@ucheba/store/prof/motivation-test/results'
import { profMotivationTestPaymentSlice } from '@ucheba/store/prof/motivation-test/payment'
import { probabilitiesSlice } from '@ucheba/store/probabilities'
import { clientProductsTariffsSlice } from '@ucheba/store/client-products/tariffs'
import { pageDataOfferSlice } from '@ucheba/store/page-data/offer'
import { pageDataAboutPlatformSlice } from '@ucheba/store/page-data/about-platform'
import { professionsSearchSlice } from '@ucheba/store/professions/search'
import { professionsIdSlice } from '@ucheba/store/professions/id'
import { siteNameSlice } from '@ucheba/store/siteName'
import { olympsIdSlice } from '@ucheba/store/olympiads-new/id'
import { articleIdSlice } from '@ucheba/store/articles/id'
import { headerSlice } from '../components/Header/store'
import { footerSlice } from '../components/Footer/store'
import { locationNoticeSlice } from '../components/DefineLocationNotice/store'

const slices = [
  advSlice,
  authSlice,
  authPhoneSlice,
  authPhoneCodeSlice,
  chatBotSlice,
  clientRequestSlice,
  clientProductsRequestsSlice,
  clientProductsRequestsPaymentSlice,
  coreSlice,
  dialogSlice,
  egeSubjectsSlice,
  egeSubjectsDetailSlice,
  egeSubjectsScheduleSlice,
  egeSchoolSlice,
  egeStatsSlice,
  eventsSlice,
  eventsIdSlice,
  eventsMySlice,
  eventsOpenLessonsSlice,
  eventsSearchSlice,
  examsSlice,
  geoipSlice,
  eventsRubricsSlice,
  eventsSimilarSlice,
  participantsSlice,
  headerSlice,
  footerSlice,
  stepFormsSlice,
  stepFormsStepSlice,
  stepFormsStartSlice,
  stepFormsStepCompleteSlice,
  institutionsEventsSlice,
  institutionItemSlice,
  institutionsRankingsSlice,
  institutionsRankingsParticipantsSlice,
  institutionsRankingsStatsSlice,
  institutionsSlice,
  institutionsProgramIdSlice,
  locationSlice,
  notificationsSlice,
  olympiadsSlice,
  pageDataProfSlice,
  pageDataConsultSlice,
  personalAchievementsSlice,
  plannerSpecialtiesSlice,
  plannerProgramsSlice,
  plannerStatisticsSlice,
  plannerPlanSlice,
  profileSlice,
  profileShortSlice,
  professionsSlice,
  profileFavoritesSlice,
  subjectsSlice,
  subjectsDetailSlice,
  specialitiesIdSlice,
  specialitiesIdSimilarSlice,
  specialitiesSearchSlice,
  tagsStatsSlice,
  tagsTreeSlice,
  userDataSlice,
  userTagsSlice,
  articlesSlice,
  eventSlice,
  ogeSubjectsSlice,
  ogeSubjectsDetailSlice,
  ogeSubjectsScheduleSlice,
  institutionsProgramTypesSlice,
  institutionsProgramsSlice,
  chancesOfAdmissionSlice,
  divisionsSlice,
  feedbacksSlice,
  questionsSlice,
  learningRequestSlice,
  profMotivationTestResultsSlice,
  profMotivationTestPaymentSlice,
  newsSlice,
  feedbackSlice,
  questionsSlice,
  institutionOlympiadsSlice,
  teachersSlice,
  partnerEmployersSlice,
  newsByIdSlice,
  profMotivationTestResultsSlice,
  profMotivationTestPaymentSlice,
  probabilitiesSlice,
  clientProductsTariffsSlice,
  pageDataOfferSlice,
  pageDataAboutPlatformSlice,
  professionsSearchSlice,
  professionsIdSlice,
  siteNameSlice,
  pageDateHeaderOffersSlice,
  olympsIdSlice,
  articleIdSlice,
  locationNoticeSlice,
]

export const wrapper = createReduxWrapper(slices)
